<template>
  <div class="h-full">
    <Scrollbar>
      <Table :options="handleOptions" :loadData="handleLoadData" title="教师管理" ref="table" :handleInfo="{ label: '操作',width: 240}">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="formSearch.real_name" placeholder="教师姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="formSearch.mobile" placeholder="联系电话"></el-input>
            </el-form-item>
            <el-form-item label="教师状态">
              <el-select v-model="formSearch.status" placeholder="状态" clearable>
                <el-option label="禁用" :value="0"></el-option>
                <el-option label="在职" :value="1"></el-option>
                <el-option label="休假" :value="2"></el-option>
                <el-option label="离职" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" @click="handleCreate" icon="el-icon-edit">添加老师</el-button>
            <el-button type="success" icon="el-icon-download" @click="handleExport">下载导入模板</el-button>
            <el-upload
              action=""
              :show-file-list="false"
              style="display: contents"
              accept=".xls,.XLS,.xlsx,.XLSX"
              :http-request="handleImport"
            >
              <el-button  icon="el-icon-upload2" type="primary" class="ml-2"
              >批量导入教师
              </el-button>
            </el-upload>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" @click="handleResetPassword(row)">重置密码</el-button>
          <el-button type="text" icon="el-icon-edit" @click="handleLogin(row)">登录</el-button>
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from './components/teacherEdit.vue'
import mixin from "@/mixin/execlExportImport";
import Cookies from "js-cookie";
export default {
  name: "panel",
  components: {
    edit
  },
  mixins: [mixin],
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        real_name:'',
        mobile: '',
        status:'',
      },
    };
  },
  mounted() {
    this.handleRefresh()
  },
  methods: {
    handleOptions(table) {
      // console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teacher/table").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.page("teacher/list", {size, page}, Object.assign(this.formSearch,search)).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 添加教师
    handleCreate() {
      this.$cloud.dialog({
        title: '添加教师信息',
        data: {},
        component: edit,
        success: '添加教师成功！',
        warning: '添加教师失败！',
        refresh: this.handleRefresh
      })
    },
    // 更新教师
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新教师信息',
        data: item,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        refresh: this.handleRefresh
      })
    },
    // 删除教师
    handleDelete(item) {
      this.$confirm('此操作将永久删除该老师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("/teacher/remove", {id: item.id}).then(res => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },

    // 导出
    handleExport() {
      let tHeader = ["姓名","钉钉的手机号",'任教学科',"班级",'任班主任的班级'];
      this.exportElxs({
        header: tHeader, //这里应该是算第二行的表头
        data: [], //数据
        filename: `小学教师信息采集模板`,
        autoWidth: true,
        bookType: "xlsx",
      });
    },
    // 导入
    handleImport(e){
      this.httpRequest(e,(data)=>{
        if(data && data.length){
          console.log(data)
          let params = {
            list:data.map(item=>{
              const room = String(item['班级']).replace(/，|,/g, '').match(/\d{3}/g)
              return {
                name: item['姓名'] || '',
                mobile: item['钉钉的手机号'] || '',
                subject: item['任教学科'] || '',
                room: room || [],
                head: item['任班主任的班级'] || '',
              }
            })
          }
          console.log('params::',params)
          this.$cloud.post('teacher/import2',params).then(res=>{
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },

    // 一键登录
    handleLogin(row){
      let teacherId = row.id
      console.log("登录",row)
      this.$message.info("登录中")
      this.$cloud.post("teacher/fast/login", {teacherId}).then(res=>{
        console.log('res',res)
        this.$message.success("登录成功")
        Cookies.remove("hankun_cloud_template_token");
        Cookies.set("hankun_cloud_template_token",res.token)
        window.location.replace('/pages/web/panel')
        // this.$cloud.go("/pages/web/panel");
      })
    },
  
    // 重置为初始密码
    handleResetPassword(row){
      this.$confirm('此操作将重置为初始密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        let id  = row.id
        this.$cloud.post("teacher/reset/password",{id}).then(()=>{
          this.$message.success("重置成功，请牢记初始密码为：123456")
        })
      }).catch(()=>{})
    }
  },
};
</script>
<style lang="scss"></style>
