<!--
 * ======================================
 * 说明： 教师  新增 - 修改
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/8/9 9:00
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
    <el-form-item label="教师姓名" prop="real_name">
      <el-input v-model="formData.real_name"></el-input>
    </el-form-item>
    <el-form-item label="手机号码" prop="mobile">
      <el-input v-model="formData.mobile"></el-input>
    </el-form-item>
    <el-form-item label="教师状态" prop="status">
      <el-select class="w-full" v-model="formData.status">
        <el-option label="禁用" :value="0"></el-option>
        <el-option label="在职" :value="1"></el-option>
        <el-option label="休假" :value="2"></el-option>
        <el-option label="离职" :value="3"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="教师性别" prop="sex">
      <el-radio-group v-model="formData.sex">
        <el-radio :label="1">男</el-radio>
        <el-radio :label="0">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="是否任课" prop="is_teach">
      <el-radio-group v-model="formData.is_teach">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="是否班主任" prop="is_headteacher">
      <el-radio-group v-model="formData.is_headteacher">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="备注" prop="remarks">
      <el-input type="textarea" v-model="formData.remarks"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value: Object,
  },
  data() {
    return {
      //表单数据结构
      formData: {
        real_name: "",
        sex: "男",
        mobile: "",
        is_teach: 0,
        is_headteacher: 0,
        remarks: '',
        status: 1,
      },
      rules: {
        real_name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {pattern: /^(?:(?:\+|00)86)?1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    if (this.value.id) {
      this.handleLoadForm(this.value.id);
    } else {
      //重置表单数据
      this.formData = {
        real_name: "",
        sex: "男",
        mobile: "",
        is_teach: 0,
        is_headteacher: 0,
        status: 1,
      }
    }
  },
  methods: {
    /**
     * 加载表单数据结构
     */
    handleLoadForm(id) {
      this.$cloud.get('teacher/detail', {id: id}).then(res => {
        this.formData = res;
      }).catch(err => {
      })
    },
    /**
     * 执行数据提交接口
     * @returns {Promise<unknown>}
     */
    handleSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let api = 'teacher/create'
            //判断是否存在ID，如果存在ID，则是修改方法
            if (this.formData.id) {
              api = 'teacher/edit'
            }
            this.$cloud.post(api, this.formData).then(res => {
              resolve(true)
            }).catch(err => {
              reject(false)
            })
          } else {
            reject(false)
          }
        })
      })
    }
  },
}
</script>
<style lang="scss" scoped>


</style>